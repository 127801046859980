import {ARRAY_BUFFER} from "@api/doinsport/config";
import {clubId, _get, _post, _put} from "@api/doinsport/services/httpService";

export const SUBSCRIPTION_CARD_URL = "clubs/subscription-cards";

export const getSubscriptionCardsClient = (clientId) =>
  _get(
    SUBSCRIPTION_CARD_URL +
    '?club.id=' + clubId +
    '&client.id=' + clientId +
    '&itemsPerPage=100&page=1' +
    '&order[canceled]=asc'
  )
;

export const getSubscriptionCardsDueDates = (itemsPerPage = 10, page = 1, search = '', filters = '') =>
  _get(
    SUBSCRIPTION_CARD_URL + '/payment-due-dates' +
    '?club.id=' + clubId +
    '&canceled=false' +
    '&itemsPerPage=' + itemsPerPage +
    '&page=' + page +
    '&search=' + search +
    filters
  )
;

export const getSubscriptionCard = (id) => _get(SUBSCRIPTION_CARD_URL + '/' + id);

export const getSubscriptionContract = (id) => _get(SUBSCRIPTION_CARD_URL + '/' + id + '/contract.pdf', ARRAY_BUFFER);

export const getRecentSubscriptionCards = (endDate) =>
  _get(
    SUBSCRIPTION_CARD_URL +
    '?club.id=' + clubId +
    '&order[endDate]=asc&endDate[after]=' + endDate
  )
;
export const exportSubscribers = () => _get(SUBSCRIPTION_CARD_URL + '.export?club.id=' + clubId);

export const exportDueDate = (fromDate, endDate, debitDate) => _get(SUBSCRIPTION_CARD_URL + '/payment-due-dates.export?club.id='+clubId + '&fromDate='+fromDate + '&toDate=' + endDate + '&debitDate=' + debitDate);

export const postSubscriptionCard = (data) => _post(SUBSCRIPTION_CARD_URL, data);

export const putSubscriptionCard = (id, data) => _put(SUBSCRIPTION_CARD_URL + '/' + id, data);
